<template>
  <div />
</template>

<script>
import LocalStorageManager from "@/LocalStorageManager";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

export default {
  name: "LoginSuccess",
  data() {
    return {
      refreshToken: "",
    };
  },
  created() {
    this.refreshToken = this.$route.query.refreshToken;
    if (this.refreshToken) {
      this.getRefreshToken();
    } else {
      this.$router.push({
        path: this.$routerPath.HOME,
      });
    }
  },
  methods: {
    async getRefreshToken() {
      LocalStorageManager.shared.clear();
      const token = {
        refreshToken: this.refreshToken,
      };
      const result = await ApiService.shared.postData(apiPath.TOKEN, token);
      if (result.code === "200" && result.data) {
        LocalStorageManager.shared.setUserData(result.data);
        this.$router
          .push({
            path: this.$routerPath.HOME,
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            path: this.$routerPath.HOME,
          })
          .catch(() => {});
      }
    },
  },
};
</script>
